<template>
  <!-- card -->
  <div
    class="
      flex flex-col
      items-stretch
      justify-center
      px-2
      py-2
      rounded-xl
      shadow-xl
      bg-dark
      card-container
    "
  >
    <!-- video preview modal (with fade transition) -->
    <transition name="fade" mode="out-in">
      <template v-if="isAPILoading || isVideoDownloading">
        <div
          class="
            flex flex-col flex-grow
            items-center
            justify-center
            py-10
            h-full
          "
        >
          <!-- heroicon - progress loader icon -->
          <svg
            class="
              relative
              animate-spin
              h-12
              w-12
              mx-auto
              text-light
              progress-circle
            "
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            ></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          <h6
            class="
              text-center text-white
              font-display font-bold
              text-xs
              sm:text-xl
              py-4
            "
          >
            Your personalized PAC video will be downloaded shortly
          </h6>
        </div>
      </template>
      <template v-else>
        <h6
          class="
            text-center text-white
            font-display font-bold
            text-xs
            sm:text-xl
            py-4
          "
        >
          Your personalized PAC video has been downloaded
        </h6>
      </template>
    </transition>
  </div>
</template>

<script>
//store
import { mapGetters } from "vuex";

//services
import { GetSingleUser } from "@/services/user.service";

import { baseURL } from "@/services/config";

export default {
  name: "DownloadVideo",
  data() {
    return {
      user: null,
      isAPILoading: true,
      isVideoDownloading: false,
      linkCopied: false,
    };
  },
  metaInfo() {
    return {
      title: "Personal PAC - Download your Video",
      meta: [
        {
          name: "description",
          content: "Download your personalized video through Personal PAC.",
        },
        {
          property: "og:title",
          content: "Personal PAC - Download your Video",
        },
        { property: "og:site_name", content: "Personal PAC" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      userDetails: "users/getUserDetails",
    }),
  },
  async mounted() {
    // shows a confirmation dialog when trying to refresh
    window.onbeforeunload = function (e) {
      if (this.isVideoDownloading) {
        e = e || window.event;
        if (e) {
          e.returnValue = "Are you sure you want to leave?";
        }
        // For Safari
        return "Are you sure you want to leave?";
        // return false;
      }
    };

    // get user details from API
    await this.getUserById(this.$route.params.id);
  },
  methods: {
    // populate target videos list
    async getUserById(id) {
      try {
        this.isAPILoading = true;
        let { data } = await GetSingleUser(id);
        if (data.code == 200 || data.message == "success") {
          this.user = data.content;
          // then download the video
          this.downloadVideoLink();
        }
      } catch {
        console.log("ERROR DV01");
      } finally {
        this.isAPILoading = false;
      }
    },
    // function to download file using streams
    downloadVideoLink() {
      window.open(baseURL + `/user/download/${this.user._id}`, "_blank");
    },
  },
};
</script>

<style scoped>
.card-container .card-inner-container {
  height: 50vh;
}
/* video modal header opacity transition */
.video-preview-modal > header {
  opacity: 0;
  z-index: 10;
}
.video-preview-modal:hover > header {
  opacity: 1;
}
/* class for copy to clipboard button tooltip (add the text in the copyTitle attribute of the button) */
.copy-link-button {
  position: relative;
}
/* Tooltip container button */
.copy-link-button::after {
  content: attr(copyTitle);
  visibility: hidden;
  width: 100%;
  opacity: 0.6;
  font-size: 0.7rem;
  background-color: #333333;
  color: #ffffff;
  text-align: center;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  bottom: calc(100% + 0.25rem);
  left: 0;
}
.copy-link-button::before {
  content: " ";
  visibility: hidden;
  position: absolute;
  top: -0.25rem; /* At the top of the tooltip */
  left: 50%;
  margin-left: -0.25rem;
  border-width: 0.25rem;
  border-style: solid;
  opacity: 0.6;
  border-color: transparent #333333 transparent transparent;
}
/* Show the tooltip text when you the show-tooltip class is assigned */
.copy-link-button.show-tooltip::after,
.copy-link-button.show-tooltip::before {
  visibility: visible;
}
</style>
